import { MEDIA_START } from '../actions/media';

const initialState = {
  active: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case MEDIA_START:
      return {
        active: action.payload.id,
      };
    default:
      return state;
  }
}