import {
  FETCH_SESSION_LOADING,
  FETCH_SESSION_SUCCESS,
  FETCH_SESSION_FAILURE,
  DELETE_SESSION_LOADING,
  DELETE_SESSION_SUCCESS,
  DELETE_SESSION_FAILURE,
} from '../actions/session';

const initialState = {
  loading: false,
  errors: null,
  data: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SESSION_LOADING:
    case DELETE_SESSION_LOADING:
      return {
        ...state,
        loading: true,
      };
    case FETCH_SESSION_FAILURE:
    case DELETE_SESSION_FAILURE:
      return {
        ...state,
        loading: false,
        errors: action.payload.errors,
      };
    case FETCH_SESSION_SUCCESS:
      return {
        loading: false,
        errors: null,
        data: action.payload.data,
      }
    case DELETE_SESSION_SUCCESS:
      window.location.reload();
      return {
        loading: false,
        errors: null,
        data: {},
      };
    default:
      return state;
  }
}
