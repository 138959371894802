import { combineReducers } from 'redux';
import sessionReducer from './session';
import activitiesReducer from './activities';
import pollsReducer from './polls';
import userContestsReducer from './userContests';
import userContestActionsReducer from './userContestActions';
import mediaReducer from './media';
import { FETCH_USER_CONTEST_SUCCESS, CREATE_USER_CONTEST_SUCCESS, FETCH_USER_CONTESTS_SUCCESS } from '../actions/userContest';
import merge from 'lodash.merge';

const appReducer = combineReducers({
  session: sessionReducer,
  activities: activitiesReducer,
  polls: pollsReducer,
  user_contests: userContestsReducer,
  user_contest_actions: userContestActionsReducer,
  media: mediaReducer,
});

export default (state, action) => {
  switch (action.type) {
    case FETCH_USER_CONTEST_SUCCESS:
    case FETCH_USER_CONTESTS_SUCCESS:
    case CREATE_USER_CONTEST_SUCCESS:
      return merge({}, state, action.payload.data);
    default:
      return appReducer(state, action);
  };
};
