export const MEDIA_START = 'MEDIA_START';

export default {
  startMedia(id) {
    return (dispatch) => {
      dispatch({
        type: MEDIA_START,
        payload: {
          id,
        },
      });
    }
  },
};