const KEYS = {
  airbrake: {
    projectId: 215004,
    projectKey: 'f1d410b8511d6edec953304f58d60106'
  },

  apple: {
    developerToken: 'eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IlVOSDdZOTZaOEMifQ.eyJpYXQiOjE1NTI1Nzg1OTYsImV4cCI6MTU2ODEzMDU5NiwiaXNzIjoiUk1MODdYR0M4OCJ9.NRc2Y8qjFV1xiufGZqxhvAAu0sd6rRS8SqdKx8klkAHsO-nDAARwwzs4quAclnvi0TbctbSo9euHMzqK6J_zEA'
  },

  googleAnalytics: {
    webPropertyId: 'UA-5543710-7'
  },

  facebook: {
    appId: '384503444962200'
  },
};

if (process.env.REACT_APP_ENV === 'staging') {
  KEYS.googleAnalytics.webPropertyId = 'UA-5543710-6';
  KEYS.facebook.appId = '504832369535061';
  KEYS.apple.developerToken = 'eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IjhHM0NGNFI5Nk0ifQ.eyJpYXQiOjE1NTI1ODAwNDMsImV4cCI6MTU2ODEzMjA0MywiaXNzIjoiUk1MODdYR0M4OCJ9.HL1MIzHZezvjg1bVA283-M5f6rICvX5ZN-YVk1cydZA6FQPY7g0T0Xp-Da981Qba9-AEcHMvS9aXSRQVxWSVYQ';
}

if (process.env.REACT_APP_ENV === 'beta') {
  KEYS.googleAnalytics.webPropertyId = 'UA-5543710-4';
  KEYS.facebook.appId = '116527605044984';
  KEYS.apple.developerToken = 'eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IlQ3NjJCV0ZMNU4ifQ.eyJpYXQiOjE1NTI1ODAxMjEsImV4cCI6MTU2ODEzMjEyMSwiaXNzIjoiUk1MODdYR0M4OCJ9.GQZqKbTXdE7uEiC3Dhc-W7CgBCMArxIBNIi_rQWCnz-10mQnEkvHybo_sg_qRr93QUDZpEzbhFLrvLjC-ke10A';
}

if (process.env.REACT_APP_ENV === 'production') {
  KEYS.googleAnalytics.webPropertyId = 'UA-5543710-4';
  KEYS.facebook.appId = '116527605044984';
  KEYS.apple.developerToken = 'eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IlQ3NjJCV0ZMNU4ifQ.eyJpYXQiOjE1NTI1ODAxMjEsImV4cCI6MTU2ODEzMjEyMSwiaXNzIjoiUk1MODdYR0M4OCJ9.GQZqKbTXdE7uEiC3Dhc-W7CgBCMArxIBNIi_rQWCnz-10mQnEkvHybo_sg_qRr93QUDZpEzbhFLrvLjC-ke10A';
}

export default {
  ACTIVITY_LIST_ID: process.env.REACT_APP_ACTIVITY_LIST_ID,
  EMBER_APP_URL: process.env.REACT_APP_EMBER_APP_URL,
  EMBED_SDK: process.env.REACT_APP_EMBED_SDK,
  API_ROOT: process.env.REACT_APP_API_ROOT,
  FB_PIXEL_IDS: process.env.REACT_APP_FB_PIXEL_IDS,
  KEYS
}
