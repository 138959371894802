import TS from '../api';
import { normalize } from 'normalizr';
import activitiesModel from '../models/activity';

export const FETCH_ACTIVITIES_LOADING = 'FETCH_ACTIVITIES_LOADING';
export const FETCH_ACTIVITIES_SUCCESS = 'FETCH_ACTIVITIES_SUCCESS';
export const FETCH_ACTIVITIES_FAILURE = 'FETCH_ACTIVITIES_FAILURE';

export default {
  fetchActivities(activityListId) {
    return async (dispatch) => {
      dispatch({
        type: FETCH_ACTIVITIES_LOADING,
      });
      try {
        const res = await TS.get(`/activities?activity_list_id=${activityListId}&limit=50`);
        const normalized = normalize(res.activities, [activitiesModel]);
        dispatch({
          type: FETCH_ACTIVITIES_SUCCESS,
          payload: {
            data: normalized.entities
          }
        });
      } catch (errors) {
        dispatch({
          type: FETCH_ACTIVITIES_FAILURE,
          payload: { errors }
        });
      }
    }
  },
};