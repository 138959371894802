import { 
  FETCH_ACTIVITIES_LOADING, 
  FETCH_ACTIVITIES_FAILURE, 
  FETCH_ACTIVITIES_SUCCESS
} from '../actions/activity';

const initialState = {
  loading: false,
  errors: null,
  data: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ACTIVITIES_LOADING:
      return {
        ...state,
        loading: false,
      };
    case FETCH_ACTIVITIES_FAILURE:
      return {
        ...state,
        errors: action.payload.errors
      };
    case FETCH_ACTIVITIES_SUCCESS:
      return { 
        loading: false,
        errors: null,
        data: action.payload.data.activities
      };
    default:
      return state
  }
}
