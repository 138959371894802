import TS from '../api';
import { normalize } from 'normalizr';
import pollModel from '../models/poll';

export const FETCH_POLL_LOADING = 'FETCH_POLL_LOADING';
export const FETCH_POLL_SUCCESS = 'FETCH_POLL_SUCCESS';
export const FETCH_POLL_FAILURE = 'FETCH_POLL_FAILURE';
export const CREATE_POLL_RESPONSE_LOADING = 'CREATE_POLL_RESPONSE_LOADING';
export const CREATE_POLL_RESPONSE_SUCCESS = 'CREATE_POLL_RESPONSE_SUCCESS';
export const CREATE_POLL_RESPONSE_FAILURE = 'CREATE_POLL_RESPONSE_FAILURE';

export default {
  fetchPoll(id, params) {
    return async (dispatch) => {
      dispatch({
        type: FETCH_POLL_LOADING,
      });
      try {
        const res = await TS.get(`/polls/${id}?contest_id=${params.contestId}&contest_action_id=${params.contestActionId}`);
        const normalized = normalize(res.poll, pollModel);
        dispatch({
          type: FETCH_POLL_SUCCESS,
          payload: {
            data: normalized.entities
          }
        });
      } catch (error) {
        dispatch({
          type: FETCH_POLL_FAILURE,
          payload: {
            error
          }
        });
      }
    }
  },

  createPollResponse(body) {
    return async (dispatch) => {
      dispatch({
        type: CREATE_POLL_RESPONSE_LOADING,
      });
      try {
        const res = await TS.post(`/poll_responses`, { 
          poll_response: body 
        });
        const normalized = normalize(res.poll, pollModel);
        dispatch({
          type: CREATE_POLL_RESPONSE_SUCCESS,
          payload: {
            data: normalized.entities
          }
        });
      } catch (error) {
        dispatch({
          type: CREATE_POLL_RESPONSE_FAILURE,
          payload: {
            error
          }
        });
      }
    }
  }
};