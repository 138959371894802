import TS from '../api';

export const FETCH_SESSION_LOADING = 'FETCH_SESSION_LOADING';
export const FETCH_SESSION_SUCCESS = 'FETCH_SESSION_SUCCESS';
export const FETCH_SESSION_FAILURE = 'FETCH_SESSION_FAILURE';

export const DELETE_SESSION_LOADING =  'DELETE_SESSION_LOADING';
export const DELETE_SESSION_SUCCESS =  'DELETE_SESSION_SUCCESS';
export const DELETE_SESSION_FAILURE =  'DELETE_SESSION_FAILURE';

export default  {
  fetchSession() {
    return async (dispatch) => {
      dispatch({
        type: FETCH_SESSION_LOADING,
      });
      try {
        const res = await TS.get('/sessions');
        dispatch({
          type: FETCH_SESSION_SUCCESS,
          payload: {
            data: res
          }
        });
      } catch (error) {
        dispatch({
          type: FETCH_SESSION_FAILURE,
          payload: {
            errors: error
          }
        });
      }
    }
  },

  deleteSession() {
    return async (dispatch) => {
      dispatch({
        type: DELETE_SESSION_LOADING,
      });
      try {
        await TS.delete('sessions');
        dispatch({
          type: DELETE_SESSION_SUCCESS,
        });
      } catch (error) {
        dispatch({
          type: FETCH_SESSION_FAILURE,
          payload: {
            errors: error
          }
        });
      }
    }
  }
};