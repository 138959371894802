import TS from '../api';
import { normalize } from 'normalizr';
import userContestModel from '../models/userContest';
import param from '../utils/param';

export const FETCH_USER_CONTEST_LOADING = 'FETCH_USER_CONTEST_LOADING';
export const FETCH_USER_CONTEST_SUCCESS = 'FETCH_USER_CONTEST_SUCCESS';
export const FETCH_USER_CONTEST_FAILURE = 'FETCH_USER_CONTEST_FAILURE';

export const FETCH_USER_CONTESTS_LOADING = 'FETCH_USER_CONTESTS_LOADING';
export const FETCH_USER_CONTESTS_SUCCESS = 'FETCH_USER_CONTESTS_SUCCESS';
export const FETCH_USER_CONTESTS_FAILURE = 'FETCH_USER_CONTESTS_FAILURE';

export const CREATE_USER_CONTEST_LOADING = 'CREATE_USER_CONTEST_LOADING';
export const CREATE_USER_CONTEST_SUCCESS = 'CREATE_USER_CONTEST_SUCCESS';
export const CREATE_USER_CONTEST_FAILURE = 'CREATE_USER_CONTEST_FAILURE';

export default {
  // Fetch a user-contest by contest-id
  fetchUserContest(id) {
    return async (dispatch) => {
      try {
        const res = await TS.get(`user_contests/${id}`);
        const normalized = normalize(res.user_contest, userContestModel);
        dispatch({
          type: FETCH_USER_CONTEST_SUCCESS,
          payload: {
            data: normalized.entities
          }
        });
      } catch (error) {
        dispatch({
          type: FETCH_USER_CONTEST_FAILURE,
          payload: {
            errors: error
          }
        });
      }
    }
  },

  createUserContest(body) {
    return async (dispatch) => {
      dispatch({
        type: CREATE_USER_CONTEST_LOADING,
      });
      try {
        const res = await TS.post('/user_contests', body);
        const normalized = normalize(res.user_contest, userContestModel);
        dispatch({
          type: CREATE_USER_CONTEST_SUCCESS,
          payload: {
            data: normalized.entities
          }
        });
      } catch (error) {
        dispatch({
          type: CREATE_USER_CONTEST_FAILURE,
          payload: {
            errors: error
          }
        });
      }
    }
  },

  fetchUserContests(params = {}) {
    return async (dispatch) => {
      try {
        const res = await TS.get(`/user_contests?${param(params)}`);
        const normalized = normalize(res.user_contests, [userContestModel]);
        dispatch({
          type: FETCH_USER_CONTESTS_SUCCESS,
          payload: {
            data: normalized.entities
          }
        });
      } catch (error) {
        dispatch({
          type: FETCH_USER_CONTESTS_FAILURE,
          payload: {
            errors: error
          }
        });
      }
    }
  }
};