import React, { useEffect, Suspense } from 'react';
import { connect } from 'react-redux';
import { Router, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import initReactFastclick from 'react-fastclick';
import ErrorBoundary from './components/ErrorBoundary';
import sessionActions from './actions/session';
import { trackPage } from './components/GoogleAnalyticsTracker';
import fbPixel from './utils/facebookPixel';
import history from './history';

import './App.scss';

const Dashboard = React.lazy(() => import('./pages/Dashboard'));
const Embed = React.lazy(() => import('./pages/Embed'));
const Activity = React.lazy(() => import('./pages/Activity'));

initReactFastclick();
fbPixel.init();

export function App(props) {
  const { fetchSession } = props;
  useEffect(() => {
    fetchSession();
    trackPage(window.location.pathname + window.location.search);
    fbPixel.trackSingle('PageView');
  }, []);

  return (
    <ErrorBoundary component="App">
       <Router history={history}>
        <Switch>
          <Route path="/embed">
            <Suspense fallback={null}>
              <Embed />
            </Suspense>
          </Route>
          <Route path="/:activity_id" render={ props =>
            <Suspense fallback={null}>
              <Activity {...props}/>
            </Suspense> }>
          </Route>
          <Route exact path="/">
            <Suspense fallback={null}>
              <Dashboard />
            </Suspense>
          </Route>
        </Switch>
      </Router>
    </ErrorBoundary>
  );
}

App.propTypes = {
  fetchSession: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  fetchSession: sessionActions.fetchSession,
};

export default connect(null, mapDispatchToProps)(App);
