import React, { Component, } from "react";
import GoogleAnalytics from "react-ga";
import constants from '../../constants';

// https://github.com/react-ga/react-ga/wiki/React-Router-v4-withTracker

GoogleAnalytics.initialize(constants.KEYS.googleAnalytics.webPropertyId);
GoogleAnalytics.set({ anonymizeIp: true });

export const trackPage = (page, options) => {
  GoogleAnalytics.set({
    page,
    ...options,
  });
  GoogleAnalytics.pageview(page);
};

const withTracker = (WrappedComponent, options = {}) => {
  const HOC = class extends Component {
    componentDidMount() {
      const page = this.props.location.pathname + this.props.location.search;
      trackPage(page, options);
    }

    componentDidUpdate(prevProps) {
      const currentPage =
        prevProps.location.pathname + prevProps.location.search;
      const nextPage =
        this.props.location.pathname + this.props.location.search;

      if (currentPage !== nextPage) {
        trackPage(nextPage, options);
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

  return HOC;
};

export default withTracker;
