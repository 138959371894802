import { Component } from 'react';
import airbrake from '../../config/errorHandler';

class ErrorBoundary extends Component {
  componentDidCatch(error, info) {
    airbrake.notify({
      error: error,
      context: {
        component: this.props.component
      },
      params: { info }
    });
  }

  render() {
    return this.props.children;
  }
}

export default ErrorBoundary;
