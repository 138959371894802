import axios from 'axios';
import constants from './constants';

const API_ROOT = constants.API_ROOT;

// configure defaults for tunespeak api
const tsAxios = axios.create({
  baseURL: API_ROOT,
  withCredentials: true,
  contentType: 'application/json',
});

const request = (verb, url, body) => {
  return new Promise((resolve, reject) => {
    tsAxios[verb](url, body).then(res => resolve(res.data)).catch(error => reject(error.response));
  }); 
};

export default {
  get: (url) => {
    return request('get', url);
  },
  post: (url, body = {}) => {
    return request('post', url, body);
  },
  put: (url, body = {}) => {
    return request('put', url, body);
  },
  delete: (url, body = {}) => {
    return request('delete', url, body);
  }
};
