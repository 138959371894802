import {
  FETCH_POLL_SUCCESS,
  CREATE_POLL_RESPONSE_SUCCESS,
} from '../actions/poll';
import merge from 'lodash.merge';

export default (state = {}, action) => {
  switch (action.type) {
    case FETCH_POLL_SUCCESS:
    case CREATE_POLL_RESPONSE_SUCCESS:
      return merge({}, state, action.payload.data.polls);
    default:
      return state
  }
};
